import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/auth";
import { useMerchantStore } from "@/stores/merchant";

export default defineNuxtRouteMiddleware((to) => {
  const { authenticated } = storeToRefs(useAuthStore()); // make authenticated state reactive
  const { profile } = storeToRefs(useMerchantStore()); // make authenticated state reactive
  const token = useCookie("token"); // get token from cookies
  if (token.value) {
    // check if value exists
    // todo verify if token is valid, before updating the state
    authenticated.value = true; // update the state to authenticated
    const decodeJWT = JSON.parse(atob(token.value.split(".")[1]).toString());
    const timeNow = new Date().valueOf() / 1000;
    const timeExp = decodeJWT.exp;

    if (timeNow >= timeExp) {
      authenticated.value = false;
      abortNavigation();
      return navigateTo("/logout/");
    }
  }

  // if token exists and url is /login redirect to homepage
  if (token.value && to?.name === "login") {
    return navigateTo("/");
  }

  // if token doesn"t exist redirect to log in
  if (!token.value && to?.name !== "login") {
    abortNavigation();
    return navigateTo("/login/");
  }

  // handle if user confirm from internal tool, user need approve tnc before access other page
  if (
    profile.value &&
    profile.value?.kyc_status === "verified" &&
    to?.name !== "index"
  ) {
    abortNavigation();
    return navigateTo("/");
  }
});
